<template>
    <div class="subject">
        <div class="banner">
            <img src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/banner-bg@2x.png"/>
        </div>
        <div class="subject-main">
            <div class="subject-head">
                <div class="subject-logo"><img :src="column.file_url"/></div>
                <div class="subject-title">{{ column.title }}</div>
            </div>
        </div>
        <div v-for="(v1,i1) in Tag_arr" :key="i1" :style="i1%2 != 0?{background: '#fff'}:{background:  '#F7F8FA'}">
<!--            <div class="subject-item" v-if="i1%2 == 0">-->
<!--                <div class="subject-item-title">{{ v1.name }}</div>-->
<!--                <div class="subject-list">-->
<!--                    <div class="subject-list-left" @click="toPath(v1.courseList[0].cid)">-->
<!--                        <div class="subject-list-left-img"><img :src="v1.courseList[0].banner"/></div>-->
<!--                        <div class="subject-list-left-info">-->
<!--                            <div class="subject-list-left-name">{{ v1.courseList[0].title }}</div>-->
<!--                            <div class="subject-list-left-time">{{ v1.courseList[0].createtime }}</div>-->
<!--                            <div class="subject-list-left-price">{{ v1.courseList[0].price=='0.00'?'免费':v1.courseList[0].price }}</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="subject-list-main">-->
<!--                        <div class="subject-list-item" v-for="(v2, i2) in v1.courseList" v-if="(i2>0 && i2<7 && v1.ismore==false) || (i2>0 && v1.ismore == true)" @click="toPath(v2.cid)">-->
<!--                            <div class="subject-list-img"><img :src="v2.banner"/></div>-->
<!--                            <div class="subject-list-name">{{ v2.title }}</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

            <div class="subject-item2">
                <div class="subject-item-title">{{ v1.name }}</div>
                <div class="subject-list">
                    <div class="subject-list-item" v-for="(v2, i2) in v1.courseList" @click="toPath(v2.cid, v2.course_category)" v-if="(i2<8 && v1.ismore==false) || (i2>=0 && v1.ismore == true)">
                        <div class="subject-list-img">
                            <img :src="v2.banner"/>
                            <div class="subject-list-count">{{ v2.study_count }}人学过</div>
                        </div>
                        <div class="subject-list-name">{{ v2.title }}</div>
                        <div class="subject-list-price">
                            <div class="subject-list-price-author">{{ v2.author }}</div>
                            <div class="subject-list-price-r">{{ v2.price=='0.00'?'免费':v2.price }}</div>
                        </div>
                        <div class="subject-list-hospital">{{ v2.company }}</div>
                    </div>
                </div>
            </div>
            <div class="subject-more" v-if="(i1%2 != 0 && v1.courseList.length>8) || (i1%2 == 0 && v1.courseList.length>7)">
                <div class="subject-more-btn" @click="handleMove(v1, i1)"><i class="iconfont icon-jiazaizhong"></i>{{ !v1.ismore?'加载更多':'收回' }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "subject",
        data() {
            return {
                column: {},
                Tag_arr: []
            }
        },
        mounted() {
            this.getdata();
        },
        methods: {
            getdata() {
                this.$axios.post(this.apiUrl.pcSpecialCourseList, {
                    id: this.$route.params.id
                }).then(res => {
                    this.Tag_arr = res.data.Tag_arr;
                    this.column = res.data.column;
                    this.Tag_arr.map(item => {
                        item.ismore = false
                    })
                })
            },
            handleMove(v1) {
                v1.ismore = !v1.ismore
                this.Tag_arr = JSON.parse(JSON.stringify(this.Tag_arr))
            },
            toPath(id, course_category) {
                // course_category 1 讲座会议 2 手术视频 3 付费课程
                switch (course_category) {
                    case 1 :
                        this.$router.push({path: `/seminars/${id}/details`})
                        break
                    case 2:
                        this.$router.push({path: `/lct/${id}/detail`})
                        break
                    case 3:
                        this.$router.push({path: `/course/${id}/detail`})
                        break
                }
            }
        }
    }
</script>
<style scoped lang="scss">
    .subject{
        .banner{
            img{
                width: 100%;
            }
        }
        .subject-main{
            position: relative;
            padding-top: 120px;
            background: #F7F8FA;
            .subject-head{
                width: 962px;
                height: 297px;
                background: url("../../assets/img/hyzt.png") center no-repeat;
                background-size: 100% 100%;
                overflow: hidden;
                position: absolute;
                top: -160px;
                left: 50%;
                margin-left: -481px;
                z-index: 5;
                .subject-logo{
                    width: 100px;
                    height: 100px;
                    margin: 40px auto 20px;
                    img{
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                    }
                }
                .subject-title{
                    width: 850px;
                    margin: 0 auto;
                    text-align: center;
                    font-size: 30px;
                    color: #A58074;
                    overflow: hidden;/*超出部分隐藏*/
                    text-overflow:ellipsis;/* 超出部分显示省略号 */
                    white-space: nowrap;/*规定段落中的文本不进行换行 */
                }
            }
        }


        .subject-item{
            width: 1200px;
            margin: 0 auto;
            padding: 40px 0;
            .subject-list{
                overflow: hidden;
                .subject-list-left{
                    width: 465px;
                    float: left;
                    background: #fff;
                    border-radius: 10px;
                    overflow: hidden;
                    &:hover{
                        cursor: pointer;
                        box-shadow: 0px 4px 13px 1px rgba(146, 157, 160, 0.25);
                    }
                    .subject-list-left-img{
                        height: 250px;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .subject-list-left-info{
                        padding: 20px 40px;
                    }
                    .subject-list-left-name{
                        overflow: hidden;/*超出部分隐藏*/
                        text-overflow:ellipsis;/* 超出部分显示省略号 */
                        white-space: nowrap;/*规定段落中的文本不进行换行 */
                        color: #333;
                        font-size: 20px;
                    }
                    .subject-list-left-time{
                        font-size: 13px;
                        color: #666;
                    }
                    .subject-list-left-price{
                        font-size: 20px;
                        color: #288CFB;
                    }
                }
                .subject-list-main{
                    float: right;
                    width: 735px;
                    overflow: hidden;
                    .subject-list-item{
                        width: 216px;
                        height: 179px;
                        margin-left: 26px;
                        background: #FFFFFF;
                        border-radius: 10px;
                        float: left;
                        margin-bottom: 20px;
                        &:hover{
                            cursor: pointer;
                            box-shadow: 0px 4px 13px 1px rgba(146, 157, 160, 0.25);
                        }
                        .subject-list-img{
                            width: 196px;
                            height: 121px;
                            margin: 10px 10px 5px;
                            position: relative;
                            img{
                                width: 100%;
                                height: 100%;
                                vertical-align: top;
                                border-radius: 4px;
                            }
                        }

                        .subject-list-name{
                            overflow: hidden;/*超出部分隐藏*/
                            text-overflow:ellipsis;/* 超出部分显示省略号 */
                            white-space: nowrap;/*规定段落中的文本不进行换行 */
                            color: #333;
                            font-size: 14px;
                            margin: 0 15px;
                        }
                    }
                }
            }
        }
        .subject-item2{
            width: 1200px;
            margin: 0 auto;
            padding: 20px 0;
            .subject-list{
                display: flex;
                flex-flow: wrap;
                .subject-list-item{
                    width: 285px;
                    height: 285px;
                    min-width: 285px;
                    max-width: 285px;
                    background: #FFFFFF;
                    border-radius: 10px;
                    margin-bottom: 20px;
                    margin-right: 20px;
                    padding-bottom: 15px;
                    &:nth-child(4n){
                        margin-right: 0;
                    }
                    &:hover{
                        cursor: pointer;
                        box-shadow: 0px 4px 13px 1px rgba(146, 157, 160, 0.25);
                    }
                    .subject-list-img{
                        width: 265px;
                        height: 150px;
                        margin: 10px 10px 5px;
                        position: relative;
                        img{
                            width: 100%;
                            height: 100%;
                            vertical-align: top;
                            border-radius: 4px;
                        }
                        .subject-list-count{
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            padding: 0 20px;
                            height: 31px;
                            background: linear-gradient(259deg, #23398A 0%, rgba(129, 150, 226, 0) 100%);
                            border-radius: 0px 0px 4px 0px;
                            font-size: 16px;
                            color: #fff;
                        }
                    }
                    .subject-list-name{
                        color: #333;
                        font-size: 16px;
                        margin: 10px;
                        height: 50px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                    }
                    .subject-list-hospital{
                        font-size: 13px;
                        color: #666;
                        margin: 0 10px;
                    }
                    .subject-list-price{
                        margin: 0 10px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .subject-list-price-author{
                            font-size: 14px;
                            color: #666;
                        }
                        .subject-list-price-r{
                            color: #288CFB;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
        .subject-item-title{
            font-size: 28px;
            color: #000;
            position: relative;
            margin-bottom: 20px;
            &:before{
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 56px;
                height: 4px;
                background: #288CFB;

            }
        }
        .subject-more{
            text-align: center;
            padding-bottom: 30px;

            .subject-more-btn{
                display: inline-block;
                width: 122px;
                height: 49px;
                line-height: 49px;
                color: #fff;
                font-weight: 500;
                background: #288CFB;
                border-radius: 68px;
                cursor: pointer;
                i{
                    margin-right: 5px;
                }
            }
        }
    }
</style>
